<template>
  <div class="card">
    <div style="background: #DBE7F2" class="p-2">
      <TitleButton :showBtn="false" :showAddNew="false" title="Current period budget variance report"/>
      <div class="row match-height mt-1">
        <div class="col-12 col-sm-6 col-md-4 col-lg-4">
          <label for="colFormLabel" >Select Project</label>
          <v-select
            placeholder="Select Project"
            :options="projects"
            label="name"
            :reduce="name => name.id"
            v-model="projectId"
            @option:selected="onSelectProject"
          />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel">Budget</label>
          <v-select
            placeholder="Select Budget"
            v-model="budgetMasterId"
            :options="donors"
            label="name"
            :reduce="name => name.id"
            @option:selected="onSelectBudget"
          />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" >Select Cost Centre</label>
          <v-select
            placeholder="Select Cost Centre"
            v-model="costCentreId"
            :options="costCentres"
            label="name"
            :reduce="name => name.id"
          />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-2">
          <label class="colFormLabel">Interval</label>
          <v-select
              placeholder="Select Interval"
              v-model="interval"
              :options="intervals"
              label="name"
              :reduce="name => name.id"
          />
        </div>
      </div>
      <DateQuerySetter
          btnTitle="Export"
          @onClickGo="exportReport('donor-report-bva')"
      >
        <div class="col-2 col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
          <button
              style="min-width: 64px;"
              @click="exportReport('code-report-bva')"
              class="btn btn-primary btn-block waves-effect waves-float waves-light"
          >Export Code Wise Report
          </button>
        </div>
      </DateQuerySetter>

    </div>
    <Loader v-if="isLoading" />
  </div>
</template>

<script setup>
import TitleButton from '@/components/atom/TitleButton'
import DateQuerySetter from '@/components/atom/DateQuerySetter'
import Loader from '@/components/atom/LoaderComponent'
import {computed, inject, onMounted, ref} from "vue";
import handleProjects from "@/services/modules/procurement/project";
import {useRoute} from "vue-router";
import handleCostCentres from "@/services/modules/ngo/costCentre";
import handleInventory from "@/services/modules/inventory";

const {fetchProjects, fetchProjectDonors} = handleProjects();
const {fetchCostCentreList} = handleCostCentres()
const {fetchHome} = handleInventory();

const $route = useRoute();
const showError = inject('showError');
const query = `?company_id=${$route.params.companyId}`;
const token = localStorage.getItem('token');
const projects = ref([]);
const donors = ref([]);
const costCentres = ref([]);
const projectId = ref(null);
const budgetMasterId = ref(null);
const costCentreId = ref(null);
const budgetHasCostCentre = ref('');
const interval = ref(null);
const isLoading = ref(false);
const donorPVAReport = ref([]);
const budgetBreakDown = ref({});
const budgetHistory = ref({});
const actual = ref({});
const homeContent = ref({});

//computed
const intervals = computed(() => {
  let intervals = [];
  if(homeContent.value.hasOwnProperty('project_intervals')) {
    Object.keys(homeContent.value.project_intervals).forEach( id => {
      intervals.push({
        id: id,
        name: homeContent.value.project_intervals[id],
      })
    })
  }
  return intervals;
})

// Methods
const onSelectProject = () => {
  isLoading.value = true;
  donorPVAReport.value = [];
  budgetHistory.value = {};
  budgetBreakDown.value = {};
  fetchProjectDonors(projectId.value, query)
    .then( res => {
      if(res.status) {
        budgetMasterId.value = null;
        donors.value = res.data;
      } else {
        showError(res.message)
      }
    })
    .catch( err => {
      showError(err.response.message);
    })
    .finally( () => isLoading.value = false)
}

let clonedCostCentresArray
const onSelectBudget = () => {
  if (!clonedCostCentresArray){
    clonedCostCentresArray = [...costCentres.value];
  }
  costCentreId.value = null;
  budgetHasCostCentre.value = '';
  // Find the donor object with the matching id
  const matchingDonor = donors.value.find(donor => donor.id === budgetMasterId.value);

  if (!matchingDonor) {
    return costCentres.value = clonedCostCentresArray;
  }

  const matchingCostCentre = costCentres.value.find(cc => cc.id === matchingDonor.cost_centre_id);

  if (!matchingCostCentre) {
    return costCentres.value = clonedCostCentresArray;
  }

  budgetHasCostCentre.value = 'yes'
  costCentres.value = [matchingCostCentre]
}

const exportReport = (type= 'donor-report-bva') => {
  if(projectId.value === null){
    showError('Please select a project');
    return;
  }
  if(budgetMasterId.value === null){
    showError('Please select a budget');
    return;
  }
  if(interval.value === null){
    showError('Please select an interval');
    return;
  }
  let objQuery = {
    company_id: $route.params.companyId,
    budget_master_id: budgetMasterId.value ?? '',
    cost_centre_id: costCentreId.value ?? '',
    budget_has_cost_centre: budgetHasCostCentre.value,
    start_date: $route.query.start,
    end_date: $route.query.end,
    interval: interval.value,
    _token: token,
    report_type: "current-period",
    report_title: "Current period budget variance report"
  }
  let params = Object.entries(objQuery).map(([key, val]) => `${key}=${val}`).join('&');
  let url = `${process.env.VUE_APP_BASE_URL}/export/${type}/${projectId.value}?${params}`;
  let a = document.createElement('a');
  a.setAttribute('href', url);
  a.click();
}

onMounted( () => {
  isLoading.value = true;
  Promise.all([
    fetchHome().then( res => {
      if(res.data) {
        homeContent.value =res.data
      }
    }),
    fetchProjects(query).then( res => {
      if(res.status) {
        projects.value = res.data;
      } else {
        showError(res.message)
      }
    }),
    fetchCostCentreList(query).then( res => {
      if(res.status) {
        costCentres.value = res.data;
      } else {
        showError(res.message)
      }
    })
  ])
    .then ( () => isLoading.value = false)
    .catch( (err) => {
      isLoading.value = false
      showError( err.response.message )
    })
    .finally( () => isLoading.value = false)
})

</script>
